@import "./library/all.min.css";
@import "./library/tabler-icons";
@import "./library/tabler-icons-filled";
@import "./library/tabler-icons-outline";
@import "./library/animate.css";
@import "./library/swiper.css";
@import "./library/calendar.css";
@import "./library/spide.min.css";
@import "./library/bootstrap";
@import "fonts";
@import "./main";


* {
  list-style: none;
  text-decoration: none;
}

/*
[data-font='100'] * {
  font-size: 100%;
}

[data-font='101'] * {
  font-size: 101% !important;
}

[data-font='102'] * {
  font-size: 102% !important;
}

[data-font='103'] * {
  font-size: 103% !important;
}

[data-font='99'] * {
  font-size: 99%;
}

[data-font='98'] * {
  font-size: 98% !important;
}

[data-font='97'] * {
  font-size: 97% !important;
}

[data-theme=mono] {
  filter: grayscale(100%);
}

[data-theme=light] {
  filter: saturate(4);
}*/


html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  overflow-x: clip;
  background: #F9F9FB;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.custom-form-control-def {
  border-radius: 5px;
  border: 1px solid #BFC4CE;
  background: #fff;
  height: 20px;
  padding: 16px 15px;
}

.custom-form-control-med {
  border-radius: 5px;
  border: 1px solid #BFC4CE;
  background: #fff;
  padding: 16px 15px;
  height: 50px;
}

.custom-form-select-def {
  border-radius: 5px;
  border: 1px solid #BFC4CE;
  height: 50px;

  & [selected] {
    color: #BFC4CE;
  }
}

.custom-shadow-normal {
  border-radius: 16px;
  background: var(--System-color-white, #FFF);
  box-shadow: 2px 2px 40px 0 rgba(0, 0, 0, 0.10);
  padding: 30px;

  @include media(991) {
    padding: 16px;
  }
}

.min-vh-50 {
  min-height: 50dvh;
}

.min-vh-60 {
  min-height: 60dvh;
}

.min-vh-70 {
  min-height: 70dvh;
}

.document-view {
  min-height: 575px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  padding: 10px;
}

.status {
  &-badge {
    position: absolute;
    border-radius: 0 0 10px 10px;
    background: var(--Status-colors-Yellow, #E7B913);
    padding: 10px 7px;
    color: #fff;
    top: 0;
    right: 30px;
    font-size: 13px;

    @include media(767) {
      position: unset;
      border-radius: 8px;
      width: max-content;
    }
  }

  &-bg {
    border-radius: 27px;
    @include media(1444) {
      border-radius: 27px 0 0 27px;
    }

    &_shadow {
      height: 100%;
      width: 100%;
      border-radius: 27px;
      background: rgba(0, 98, 255, 0.50);
      @include media(1440) {
        border-radius: 27px 0 0 27px;
      }
      position: absolute;
      top: 0;
    }
  }

}

.bg-transparent[type="checkbox"]:checked {
    background-color: #0062FF!important;
}


.list-step {
  padding: 0;

  &.vertical {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: min-content;

    @include media(1199) {
      width: 100%;
    }

    & .list-step_item__number {
      background: #BFC4CE;
      transform: rotate(45deg);
      border: unset;
      color: #fff;
      margin-right: 35px;
    }

    & .list-step_item {
      margin-bottom: 0;
    }

    & .list-step_item:nth-child(4) .list-step_item__number {
      margin-right: 0;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    &.active {
      & .list-step_item__line {
        background: #0062FF;
      }

      & .list-step_item__number {
        background: #0062FF;
        color: #fff;
        border: unset;
      }
    }

    &__line {
      position: absolute;
      height: 20px;
      background: #939699;
      width: 2px;
      top: -20px;
      left: 11px;

      @include media(576) {
        height: 40px;
        z-index: 0;
        top: -30px;
      }

      &_top {
        background: #0062FF;
        position: absolute;
        left: 0;
        height: 7px;
        z-index: 3;
      }

      &_bottom {
        background: #BFC4CE;
        position: absolute;
        left: 0;
        width: 100%;
        height: 7px;
        z-index: 2;
      }
    }


    &__number {
      width: 24px;
      height: 24px;
      border: 1.5px solid #939699;
      background: #fff;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 600;
      position: relative;
      z-index: 5;

      margin-right: 15px;
      color: #939699;
    }

    &__rotate {
      transform: rotate(-45deg);
    }

    &__text {
      color: #307ADB;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

.download-list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_icon {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e5F0FF;
    border-radius: 6px;
    color: #0062FF;
  }
}

.modal-header .btn-close {
  box-sizing: border-box;
}

.after-clear::after {
  content: none!important;
}

.btn-nav {
  width: 38px;
  height: 38px;
}


.custom-form-control-def::placeholder,
.custom-form-control-med::placeholder {
  color: #BFC4CE;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 118.75% */
}


.custom-form-control-placeholder::placeholder {
  color: #BFC4CE;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 118.75% */
}

.text-three-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.focus-none:focus {
  outline: unset !important;
  box-shadow: unset !important;
}


.rotate-45 {
  transform: rotate(45deg);
}

.cursor-pointer {
  cursor: pointer;
}

.img-cover {
  object-fit: cover;
}

.img-cover__center {
  object-fit: cover;
  object-position: left center;
}

.scroll-none::-webkit-scrollbar,
.scroll-none::-webkit-scrollbar-thumb,
.scroll-none::-webkit-scrollbar-track-piece,
.scroll-none::-webkit-scrollbar-track,
.scroll-none::-webkit-scrollbar-corner {
  width: 0;
  height: 0;
}

.scroll-style::-webkit-scrollbar {
  width: 10px;
}

.scroll-style::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-style::-webkit-scrollbar-thumb {
  background: #888;
}

.scroll-style::-webkit-scrollbar-thumb:hover {
  background: #555;
  color: rgba(0, 170, 255, 0.6);
}

.canvasjs-chart-credit {
  display: none;
}

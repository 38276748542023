// Loop over each breakpoint
@each $breakpoint in map-keys($grid-breakpoints) {

  // Generate media query if needed
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Loop over each utility property
    @each $key, $utility in $utilities {
      // The utility can be disabled with `false`, thus check if the utility is a map first
      // Only proceed if responsive media queries are enabled or if it's the base media query
      @if type-of($utility) == "map" and (map-get($utility, responsive) or $infix == "") {
        @include generate-utility($utility, $infix);
      }
    }
  }
}

// RFS rescaling
@media (min-width: $rfs-mq-value) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @if (map-get($grid-breakpoints, $breakpoint) < $rfs-breakpoint) {
      // Loop over each utility property
      @each $key, $utility in $utilities {
        // The utility can be disabled with `false`, thus check if the utility is a map first
        // Only proceed if responsive media queries are enabled or if it's the base media query
        @if type-of($utility) == "map" and map-get($utility, rfs) and (map-get($utility, responsive) or $infix == "") {
          @include generate-utility($utility, $infix, true);
        }
      }
    }
  }
}


// Print utilities
@media print {
  @each $key, $utility in $utilities {
    // The utility can be disabled with `false`, thus check if the utility is a map first
    // Then check if the utility needs print styles
    @if type-of($utility) == "map" and map-get($utility, print) == true {
      @include generate-utility($utility, "-print");
    }
  }
}


// Font size Responsive

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @if $fonts > 0 {
      // Allow columns to stretch full width below their breakpoints
      @for $i from 1 through $fonts {
        .fs#{$infix}-#{$i} {
          font-size: #{$i}px !important;
        }
      }
    }
  }
}


$sizeBox: (
        0: 0,
        5: 5,
        10:10,
        15:15,
        20:20,
        25:25,
        30:30,
        33:33,
        35:35,
        40:40,
        45:45,
        50:50,
        55:55,
        60:60,
        65:65,
        70:70,
        75:75,
        80:80,
        85:85,
        90:90,
        95:95,
        100:100,
) !default;

@each $containerSize, $oneSize in $grid-breakpoints {
  @each $sizeBox_s, $sizeBox_h in $sizeBox {
      @if $oneSize > 0 {
        @media (min-width: #{$oneSize}) {
          .w-#{$containerSize}-#{$sizeBox_s} {
            width: #{$sizeBox_h}% !important;
          }

          .w-#{$containerSize}-auto {
            width: auto !important;
          }
          .h-#{$containerSize}-#{$sizeBox_s} {
            height: #{$sizeBox_h}% !important;
          }
        }
      } @else {
        .w-#{$sizeBox_s} {
          width: #{$sizeBox_h}% !important;
        }

        .w-auto {
          width: auto !important;
        }
        .h-#{$sizeBox_s} {
          height: #{$sizeBox_h}% !important;
        }
      }
    }
}


// Line-height size Responsive


@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @if $line-height-size > 0 {
      // Allow columns to stretch full width below their breakpoints
      @for $i from 1 through $line-height-size {
        .lh#{$infix}-#{$i} {
          line-height: #{$i}px !important;
        }
      }
    }
  }
}

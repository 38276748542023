@mixin media($grid-breakpoints) {
  @media(max-width: #{$grid-breakpoints}px) {
    @content;
  }
}

@for $i from 0 through 20 {
  .custom-rounded-#{$i} {
    border-radius: #{$i}px !important;
  }
}

@for $i from 0 through 20 {
  .custom-zindex-#{$i} {
    z-index: #{$i};
  }
}

.main-body {
  overflow: unset;
  height: unset;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100dvh;
    background: #fff;
    position: absolute;
    top: -100%;
    opacity: 0;
    transition: all 0.2s linear;
    z-index: -1;
  }

  &.active-navbar {
    overflow: hidden;
    height: 100dvh;

    &::before {
      top: 0;
      opacity: 1;
      z-index: 99;

    }
  }
}

.main-body:not(.index) .header-container {
  @include media(991) {
    background: #fff;
    border-radius: unset;
  }
}

.main-dashboard {
  display: flex;
  min-height: 100dvh;
}

.nav-right {
  &.active-navbar {
    display: none !important;
  }
}

/* Header Style start */

.header {
  & .navbar {
    z-index: 100;
  }

  &-container {
    border-radius: 16px;
    background-color: #fff;
    padding-top: 14px;
    padding-bottom: 14px;

    @include media(991) {
      background: transparent;
    }

    & .navbar-brand > img {
      @include media(1200) {
        width: 135px;
      }

      @include media(576) {
        width: 180px;
      }
    }

    & .navbar-nav .nav-item > .nav-link {
      position: relative;
      color: #888;
      font-weight: 300;
      letter-spacing: -0.444px;

      @include media(1200) {
        font-size: 13px;
      }

      @include media(991) {
        font-size: 22px;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
      }


      &.active {
        font-weight: 700;
        color: #0062FF;

        &::before {
          content: '';
          display: block;
          height: 3px;
          width: 90%;
          border-radius: 12px;
          background: #0062ff;
          position: absolute;
          bottom: -22px;
          left: 6%;

          @include media(1200) {
            bottom: -14px;
          }

          @include media(991) {
            width: 100%;
            left: 0;
            height: 1px;
            background: #BFC4CE;
            bottom: 0;
          }
        }
      }

      &::before {
        content: '';
        display: none;
        position: absolute;


        @include media(991) {
          display: block;
          width: 100%;
          left: 0;
          height: 1px;
          background: #BFC4CE;
          bottom: 0;
        }
      }


    }

    & .navbar-nav .nav-item:nth-child(6) {
      & .nav-link {
        &::before {
          display: none;
        }
      }
    }
  }

  &-main {
    position: relative;
    background-image: url("../images/bg-header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    height: 67dvh;
    border-radius: 87px;
    z-index: 0;
    display: flex;
    align-items: center;

    @include media(767) {
      height: 60dvh;
      background-image: url("../images/bg-m-header.png");
      border-radius: 42px;
    }

    @include media(380) {
      height: 80dvh;
    }


    &_card {
      bottom: -80px;
      position: absolute;
      @include media(991) {
        bottom: -45px;
      }

      @include media(767) {
        position: unset;
      }

      & .card-custom-img {
        position: absolute;
        right: 20px;
        bottom: 20px;
      }

      & .img-fluid {
        width: 100%;
        border-radius: 12px;
      }

      & .background-white-05 {
        background: rgba(204, 230, 241, 0.50);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 12px;
      }

      & .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & .card {
        box-shadow: -3.993px 6.988px 39.929px 0px rgba(0, 0, 0, 0.05);
      }
    }

    &::after {
      content: "";
      display: block;
      width: 100.2%;
      height: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, #F9F9FB 23.33%, rgba(217, 217, 217, 0.00) 100%);
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.35);
      border-radius: 87px;
      @include media(767) {
        border-radius: 42px;
      }
    }
  }
}

/* Header Style end */

/* Services Style start */

.services {
  position: relative;
  margin-top: 120px;
  @include media(767) {
    margin: 0;
  }

  &-bg {
    position: absolute;
    bottom: -50%;
    width: 100%;
    z-index: 0;

    @include media(991) {
      display: none !important;
    }
  }

  & .box {
    border-radius: 10px;
    background: #fff;
    padding: 30px 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }

  & .item {
    background: #fff;
    border-radius: 17px;
    box-shadow: -4px 7px 40px 0px rgba(0, 0, 0, 0.05);
    height: 100%;
    padding: 33px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    position: relative;
    z-index: 2;

    &-img {
      width: 25%;

      @include media(575) {
        width: 30%;
      }

      & > img {
        width: 100%;
      }
    }

    @include media(767) {
      padding: 30px;
    }
  }
}

/* Services Style end */

/* Links and Ask Style Start */

.links {

  &-container {

  }

  &-swiper {
    &_box {
      min-height: 140px;
      margin-bottom: 10px;
    }

    &_nav {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      height: 45px;

      & > * {
        position: unset;
        height: 38px;
        width: 38px;
        top: unset;
        margin: 0;

        @include media(325) {
          width: 25px;
          height: 25px;
          &:first-child {
            margin-right: 5px !important;
          }
        }
      }
    }


  }

}

.ask {

  &-block {
    @include media(767) {
      background: #fff;
      padding: 16px;
      border-radius: 16px;
      box-shadow: 2px 2px 40px 0px rgba(0, 0, 0, 0.10);
    }
  }
}

/* Links and Ask Style end */

/* Requests Style start */

.requests {

  &-progress {
    padding: 38px 52px;
    border-radius: 17px;
    background: #fff;
    box-shadow: -4px 7px 40px 0px rgba(0, 0, 0, 0.05);

    @include media(767) {
      padding: 30px 26px;
    }

    @include media(325) {
      padding: 25px 20px;
    }

    &_col {
      position: relative;
      display: flex;
      flex-direction: column;

      @include media(767) {
        flex-direction: row;
        align-items: center;
      }

      &:first-child .requests-progress_step {
        background: #C4E4FF;
      }

      &:nth-child(2) .requests-progress_step {
        background: #E0F1FF;
      }

      &:nth-child(3) .requests-progress_step {
        background: #EEF7FF;
      }

      &:nth-child(4) .requests-progress_step {
        background: #fff;
      }

      &:nth-child(4) .requests-progress_line {
        &:after {
          content: ' ';
          display: flex;
          border-bottom: 2px solid #BCD6FF;
          border-right: 2px solid #BCD6FF;
          height: 34px;
          width: 34px;
          transform: rotate(-45deg);
          position: absolute;
          right: 5px;
          top: -16px;

          @include media(767) {
            transform: rotate(45deg);
            top: unset;
            bottom: 4px;
            right: -16px;
          }
        }
      }
    }

    &_step {
      width: 82px;
      height: 82px;
      filter: drop-shadow(-4px 7px 40px rgba(0, 0, 0, 0.05));
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #0062FF;
      font-size: 40px;
      font-weight: 300;
      position: relative;
      z-index: 1;

      @include media(767) {
        margin-right: 20px;
      }

      @include media(325) {
        width: 62px;
        height: 62px;
      }
    }

    &_line {
      background: #BCD6FF;
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 0;
      top: 41px;
      @include media(767) {
        width: 2px;
        height: 100%;
        left: 41px;
        top: 0;
      }

      @include media(325) {
        left: 31px;
      }
    }
  }
}

/* Requests Style end */

/* Main sidebar Style start */

.main-sidebar {
  border-radius: 17px;
  background: var(--System-color-white, #FFF);
  box-shadow: -4px 7px 40px 0px rgba(0, 0, 0, 0.10);
  padding: 20px 0;
  min-width: 265px;

  @include media(991) {
    margin-right: -12px;
    margin-left: -12px;
    border-radius: unset;
    padding-top: 0;
    padding-bottom: 0;
  }

  &_list {
    margin-bottom: 0;
    padding: 0;
    margin-right: 20px;
    margin-left: 20px;

    @include media(991) {
      display: flex;
      align-items: center;
      overflow-x: scroll;
    }

    &__item {
      color: #4C5671;
      font-size: 15px;
      padding: 15px 5px;
      border-bottom: 1px solid #E3E7F0;
      position: relative;

      @include media(991) {
        white-space: nowrap;
        margin-right: 15px;
        padding-right: 0;
        padding-left: 0;
        border-bottom: unset;
        &::before {
          content: unset !important;
        }
      }

      &.active {
        color: #2A8DFF;

        @include media(991) {
          &::after {
            content: "";
            border-radius: 26px;
            background: #0062FF;
            height: 4px;
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
          }
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          left: -20px;
          width: 6.5px;
          height: 100%;
          top: 0;
          background: #0062FF;
        }
      }
    }
  }
}

/* Main sidebar Style end */

/* Sidebar Style start */

.sidebar {
  width: 255px;
  height: 100%;
  transition: all 0.2s linear;

  &.active {
    width: 70px;

    .sidebar-logo {
      display: none;
    }

    .sidebar-list {
      padding: 11px;
    }

    .sidebar-dropdown {
      text-align: center;
    }

    .sidebar-list_item {
      padding: 10px;
      text-align: center;
    }

    .sidebar-icon-right {
      display: none;
    }

    .sidebar-list_item span {
      display: none;
    }
  }

  &-dropdown {
    text-align: start;
  }

  &-list {
    padding: 11px 11px 11px 25px;
    background: #fff;
    height: 100%;
    margin-bottom: 0;

    &_item {
      padding: 16px 10px;
      position: relative;

      &.active {
        background: #F6F6F6;

        & i {
          color: #0062FF;
        }

        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 3px;
          height: 100%;
          background: #0062FF;
          left: 0;
          top: 0;
        }
      }
    }
  }

  &-header {
    padding: 16px 30px;
    background: #fff;
    width: 100%;
    display: flex;
    @include media(991) {
      flex-wrap: wrap;
    }
  }
}

/* Sidebar Style end */

/* Dashboard Style start */

.dashboard {
  &-content {
    padding: 22px 30px;
  }

  &-list {
    padding: 20px 0;
    border-radius: 12px;
    background: #fff;
    margin-bottom: 0;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.08);
  }
}

.submenu {
  border-radius: 17px;
  background: #fff;
  box-shadow: -4px 7px 40px 0px rgba(0, 0, 0, 0.05);
  padding: 25px;

  &-list {
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0;

    &_item {
      border-radius: 10px;
      background: #F7F7F7;
      padding: 12px 10px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
  }
}

/* Dashboard Style end */

/* Footer Style start */

.footer {
  background: #fff;
  padding-top: 75px;
  padding-bottom: 70px;
  margin-top: 30px;

  @include media(767) {
    padding: 20px 10px 20px 30px;
  }

  @include media(325) {
    padding: 20px 10px;
  }

  &-bg {
    position: absolute;
    width: 345px;
    right: 100px;
  }
}

/* Footer Style end */


/* Login Style Start */

.login-block {
  &_bg {
    background-image: url("../images/bg-login.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.50);
      z-index: 0;
    }

    @include media(767) {
      display: none;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      z-index: 1;
      max-width: 280px;
      margin-bottom: 80px;
      @include media(1440) {
        max-width: 240px;
      }

      &-img {
        width: 120px;
        margin-bottom: 15px;
      }

      &-p {
        color: #696974;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 300;
        text-transform: uppercase;
        font-size: 20px;

        @include media(1440) {
          font-size: 14px;
        }
      }
    }
  }

  &_form {
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include media(767) {
      background-image: url("../images/bg-login.png");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.50);
        z-index: 0;
      }
    }

    &-img {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      @include media(767) {
        display: none;
      }
    }

    &__item {
      min-width: 70%;
      padding: 15px 25px;
      position: relative;
      z-index: 1;


      @include media(767) {
        padding: 30px 25px;
        height: 90dvh;
        border-radius: 22px;
        background: rgba(255, 255, 255, 0.72);
        backdrop-filter: blur(14px);
      }

      @include media(320) {
        overflow-y: scroll;
      }
    }
  }
}

/* Login Style End */

